@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,400italic");

@font-face {
    font-family: "Wigrum";
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot");
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot?#iefix") format("embedded-opentype"),
        url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Wigrum";
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot");
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot?#iefix") format("embedded-opentype"),
        url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Wigrum";
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot");
    src: url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot?#iefix") format("embedded-opentype"),
        url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}