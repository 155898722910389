@import '../../styles/brand-colors';

.float-right {
    float: right;
}

.disabled-icon {
    & path {
        fill: $midnight-20
    }
}

.MuiTableCell-body {
    padding: 6px 16px  !important;
}