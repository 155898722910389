@import '../../styles/brand-colors';
.modal-module_modalWindow__1kWze{
  z-index:15;
  & div.modal-module_medium__3xb3r {
    padding: 0;

    & span {
      margin-top: 0;
    }
    & hr {
      margin: 0 0 10px 0;
    }
    & .confirmation-title {
      margin-top: 14px;
      margin-bottom: 8px;
      text-align: center;
    }

    & .modal-body {
      margin: 14px 22px 8px 22px;
      & p {
        text-align: center;
        word-break: normal;
      }
    }

    & .button-div{
      margin-top: 15px;
      display: inline-block;
      width: 100%;

      & button{
        float: right;
      }
    }

  }
}

.modal-alert-icon {
  display: inline-block;
  width: 100%;
  margin-bottom: 18px;
  & path {
    fill: $warning;
    align-content: center;
  }
}
