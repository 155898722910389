@import "../../styles/brand-colors";
.tree-editor {
    min-height: calc(100vh - 250px);
    padding-bottom: 10px;
}

.container-form {
    padding-top: 20px;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.info-paragraph {
    color: $dusk-lighter;
    font-size: 14px;
    text-align: justify;
    text-justify: inter-word;
    margin-left: 50px;

    & path {
        fill: $dusk-lightest;
    }
}

control.is-invalid:focus {
    box-shadow: none;
}

.character-counter {
    text-align: right;
    font-size: 12px;
}

.form-control:disabled {
    background: $dusk-three !important;
}

@media (max-width: 992px) {
    .tree-editor {
        height: auto;
    }
}

@media (max-width: 992px) {
    .info-paragraph {
        margin-left: 10px;
    }
}
