@import "../../styles/brand-colors";
.MuiBox-root .css-19kzrtu {
    padding: 10px 0px !important;
}

.MuiButtonBase-root {
    text-transform: none !important;
    color: #003349 !important;
    font-family: "Nunito Sans" !important;

    &.Mui-disabled {
        color: gray !important;
    }
}

.container {
    margin: 0;
    max-width: unset !important;
    padding-right: 0 !important;
}
.remove-padding-right {
    padding-right: 0px !important; ;
    margin-right: 0px !important; ;
}

.remove-padding-left {
    padding-left: 0px !important; ;
    margin-left: 0px !important; ;
}

.font-custom  {
    font-family: "Nunito Sans", sans-serif !important;
}

.box-left {
    padding-right: 10px;
}

.box-right {
    padding-left: 10px;
}

.Mui-selected.Mui-disabled {
    color: black !important;
}