@import '../../styles/brand-colors';
.Draft {
    background-color:  $cafe !important;
    color: $white !important;
    min-width: 60px;
}

.Live {
    background-color: $moss !important;
    color: $white !important;
    min-width: 60px;
}