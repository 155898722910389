@import "styles/brand-colors";
@import "styles/font-styles";

.without-padding {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.form-control:focus {
  box-shadow: none;
  border-color: $input-border;
}

textarea {
  border-radius: 5px;
  border-color: $input-border;
  outline: none;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus
{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  border-color: $input-border !important;
}

.is-invalid:hover, .is-invalid:focus, .is-invalid:active  {
  box-shadow: none !important;
  border-color: $is-invalid !important;
}

.alertmessage-module_messageBox__2juVW {
  margin: 0px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.ups-title {
  font-family: Wigrum, Helvetica, Arial, sans-serif !important;
  font-size: 28px;
  font-weight: bold;
  color: $gray-one;
}