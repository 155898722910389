@import "../../styles/brand-colors";
.header {
    margin-top: 20px !important;
    margin-bottom: 5px;
}

.sub-header {
    border-bottom: solid 1px $midnight-20;
    padding: 10px 20px;
    font-weight: bold;
}

.icon-info {
    padding-right: 10px;
    float: right;
    cursor: pointer;

    & path {
        fill: $dusk-lightest;
    }
}
