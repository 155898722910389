@import "../../styles/brand-colors";
.navbar-vacasa {
    background: $midnight;
    height: 64px;
    box-shadow: 0px 2px 4px $shadow-color;
}

.navbar-vacasa-logo {
    color: $white;
    font-size: 28px;
    margin-left: 5px;
    font-weight: 400;
    font-family: Wigrum, Helvetica, Arial, sans-serif;
}

.navbar-vacasa-logo-info {
    color: $white;
    font-size: 10px;
    margin-left: 5px;
}

.navbar-vacasa-icon {
    margin-right: 5px;
    margin-left: 5px;
}

.navbar-vacasa-dropdown {
    color: $white !important;
    margin: 10px;
}

.navbar-vacasa-nav-link {
    color: $white !important;
    cursor: pointer;
    margin: 10px;
}

.navbar-vacasa-dropdown-text {
    margin-right: 10px;
}

.navbar-vacasa-icon-color {
    & path {
        fill: $white;
    }
}

.navbar-brand {
    padding-top: 0.5rem !important;
    margin-left: 0.8rem !important;
}

.navbar-brand:hover {
    cursor: pointer;
}

.navbar-vacasa-submenu-link {
    color: $white !important;
}
