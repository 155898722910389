@import "../../styles/brand-colors";
.border {
    border-radius: 4px;
    border: solid 1px $midnight-20;
}

.sub-header {
    border-bottom: solid 1px $midnight-20;
    padding: 10px 20px;
    font-weight: bold;
}
