@import "../../styles/brand-colors";
.float-right {
    float: right;
    display: flex;

    & label {
        text-align: right;
        margin-top: 10px;
    }

    & form {
        padding-right: 10px;
    }
}

.form-control {
    margin: 10px 150px 5px 10px !important;
    height: 40px;
}

.padding-right {
    padding-right: 5px;
}

.button-module_secondary__1CQyn {
    margin-left: 10px;
}

.user-tree-button {
    margin-top: 10px;
    width: 120px;
    height: 40px;
}

.button-module_secondary__1CQyn:hover {
    color: $white !important;
}

.padding-left {
    margin-left: 10px;
}

.button-module_secondary__1CQyn {
    margin-left: 10px;
}

.button-module_info__3sbf_:hover {
    color: $lake !important;
}
.return-icon {
    cursor: pointer;
    & path {
        fill: $gray-three;
    }
}

.search-icon {
    cursor: pointer;
}
