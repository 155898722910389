@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,400italic);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,400italic);
.header{margin-top:20px !important;margin-bottom:5px}.sub-header{border-bottom:solid 1px #ccd6db;padding:10px 20px;font-weight:bold}.icon-info{padding-right:10px;float:right;cursor:pointer}.icon-info path{fill:#969a9c}

.border{border-radius:4px;border:solid 1px #ccd6db}.sub-header{border-bottom:solid 1px #ccd6db;padding:10px 20px;font-weight:bold}

.float-right{float:right}.padding-right{padding-right:5px}.button-module_secondary__1CQyn:hover{color:#fff !important}

.float-right{float:right}.disabled-icon path{fill:#ccd6db}.MuiTableCell-body{padding:6px 16px  !important}

.Draft{background-color:#ddad49 !important;color:#fff !important;min-width:60px}.Live{background-color:#6ba342 !important;color:#fff !important;min-width:60px}

.loading{position:relative}.loader-dots{position:absolute;top:50%;left:50%}.loader-dots div{background-color:#0078ab;width:8px;height:8px;margin:0 2px;display:inline-block;animation:sk-bouncedelay 0.8s infinite ease-in-out both}@media screen and (max-width: 768px){.loader-dots{margin-top:10%}}.loader-dots .bounce1{animation-delay:-0.3s}.loader-dots .bounce2{animation-delay:-0.15s}@keyframes sk-bouncedelay{0%,80%,100%{transform:scale(0.8)}40%{transform:scale(1);opacity:1}}.padding{padding-top:500px}

.modal-module_modalWindow__1kWze{z-index:15}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r{padding:0}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r span{margin-top:0}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r hr{margin:0 0 10px 0}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .confirmation-title{margin-top:14px;margin-bottom:8px;text-align:center}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .modal-body{margin:14px 22px 8px 22px}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .modal-body p{text-align:center;word-break:normal}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .button-div{margin-top:15px;display:inline-block;width:100%}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .button-div button{float:right}.modal-alert-icon{display:inline-block;width:100%;margin-bottom:18px}.modal-alert-icon path{fill:#ddad49;align-content:center}

.navbar-vacasa{background:#003349;height:64px;box-shadow:0px 2px 4px rgba(0,0,0,0.25)}.navbar-vacasa-logo{color:#fff;font-size:28px;margin-left:5px;font-weight:400;font-family:Wigrum, Helvetica, Arial, sans-serif}.navbar-vacasa-logo-info{color:#fff;font-size:10px;margin-left:5px}.navbar-vacasa-icon{margin-right:5px;margin-left:5px}.navbar-vacasa-dropdown{color:#fff !important;margin:10px}.navbar-vacasa-nav-link{color:#fff !important;cursor:pointer;margin:10px}.navbar-vacasa-dropdown-text{margin-right:10px}.navbar-vacasa-icon-color path{fill:#fff}.navbar-brand{padding-top:0.5rem !important;margin-left:0.8rem !important}.navbar-brand:hover{cursor:pointer}.navbar-vacasa-submenu-link{color:#fff !important}

.not-found{padding-top:100px;text-align:center}

.float-right{float:right}.padding-right{padding-right:5px}.button-module_secondary__1CQyn{margin-left:10px}.button-module_secondary__1CQyn:hover{color:#fff !important}.padding-left{margin-left:10px}.button-module_secondary__1CQyn{margin-left:10px}.button-module_info__3sbf_:hover{color:#0078ab !important}.return-icon{cursor:pointer}.return-icon path{fill:#99adb6}

.tree-editor{min-height:calc(100vh - 250px);padding-bottom:10px}.container-form{padding-top:20px;padding-left:15px !important;padding-right:15px !important}.info-paragraph{color:#707579;font-size:14px;text-align:justify;text-justify:inter-word;margin-left:50px}.info-paragraph path{fill:#969a9c}control.is-invalid:focus{box-shadow:none}.character-counter{text-align:right;font-size:12px}.form-control:disabled{background:#f8f9f9 !important}@media (max-width: 992px){.tree-editor{height:auto}}@media (max-width: 992px){.info-paragraph{margin-left:10px}}

.category-defaults{background:#f5f7f8;border-radius:0 0 8px 8px;padding:10px}.container-tree{padding:10px 0px}.tree-icon-color path{fill:#fff}.MuiTreeItem-group{border-left:1px solid #707579 !important;border-radius:0 10px !important}.MuiTreeItem-group .category-defaults{border-left:1px solid #f5f7f8 !important;margin-left:-2px}.css-po2otw{margin:50px !important}hr{margin:5px 0 5px 0 !important}div{font-family:Nunito !important}.pill{margin:3px 3px 0px 0px;background-color:#bfc2c3 !important;color:white !important}


.MuiBox-root .css-19kzrtu{padding:10px 0px !important}.container{margin:0;max-width:unset !important;padding-right:0 !important}.remove-padding-right{padding-right:0px !important;margin-right:0px !important}.remove-padding-left{padding-left:0px !important;margin-left:0px !important}.font-custom{font-family:"Nunito Sans", sans-serif !important}

.floating-panel{font-family:"Nunito";border-radius:0 0 0.2rem;box-shadow:-2px 0 4px -2px #cfcfcf;color:#707579;border-left:1px solid #dde1e5}.floating-panel .property-row{padding-top:15px}.floating-panel .css-1lwbda4-MuiStack-root{padding-right:0}.floating-panel .modal-module_modalClose__pvct8{margin-top:-8px;left:4%;right:unset}.floating-panel .modal-title{padding:10px;width:100%;display:inline-grid}.floating-panel .modal-title b{text-align:center}.floating-panel hr{margin:0 0 10px 0}.floating-panel .modal-body{margin:14px 22px 8px 22px}.floating-panel .modal-body p{size:16px;color:#707579}.floating-panel .modal-body input.radio{width:auto;size:20px;margin-left:10px;margin-right:10px;margin-top:4px}.floating-panel .modal-body input:not(:focus){color:#707579}.floating-panel .modal-body textarea{width:100%;padding:5px 8px 5px 8px}.floating-panel .modal-body textarea:not(:focus){color:#707579}.table-container{border:1px solid #b2c2c8;border-radius:5px;margin-left:0 !important}.table-container thead{height:45px;border-bottom:1px solid #b2c2c8}.table-container tr:not(:last-child){border-bottom:1px solid #b2c2c8}.close-icon{cursor:pointer}.close-icon path{fill:#969a9c}.main-title{text-align:center}.PrivateNotchedOutline-root-1{top:0 !important}.footer{padding:10px !important;text-align:right}.modal-body-custom{height:530px;overflow-y:auto !important}.radio-group-title{padding-top:10px !important}.tree-checkbox-radio{color:#99adb6 !important}.tree-checkbox-radio:hover{background-color:#fafbfb !important}.tree-checkbox-radio.Mui-checked{color:#0078ab !important}.tree-checkbox-radio.Mui-disabled{opacity:0.5}textarea:focus{outline:none !important}textarea::placeholder{color:#ced4da}.MuiInputBase-input{min-height:26px;font-size:14px !important}



.modal-module_modalWindow__1kWze{z-index:15}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r{padding:0}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r span{margin-top:0}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r hr{margin:0 0 10px 0}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .confirmation-title{margin-top:14px;margin-bottom:8px;text-align:center}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .modal-body{margin:14px 22px 8px 22px}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .modal-body p{text-align:center}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .button-div{margin-top:15px;display:inline-block;width:100%}.modal-module_modalWindow__1kWze div.modal-module_medium__3xb3r .button-div button{float:right}.modal-alert-icon{display:inline-block;width:100%;margin-bottom:18px}.modal-alert-icon path{fill:#ddad49;align-content:center}

.MuiBox-root .css-19kzrtu{padding:10px 0px !important}.MuiButtonBase-root{text-transform:none !important;color:#003349 !important;font-family:"Nunito Sans" !important}.MuiButtonBase-root.Mui-disabled{color:gray !important}.container{margin:0;max-width:unset !important;padding-right:0 !important}.remove-padding-right{padding-right:0px !important;margin-right:0px !important}.remove-padding-left{padding-left:0px !important;margin-left:0px !important}.font-custom{font-family:"Nunito Sans", sans-serif !important}.box-left{padding-right:10px}.box-right{padding-left:10px}.Mui-selected.Mui-disabled{color:black !important}

.float-right{float:right;display:flex}.float-right label{text-align:right;margin-top:10px}.float-right form{padding-right:10px}.form-control{margin:10px 150px 5px 10px !important;height:40px}.padding-right{padding-right:5px}.button-module_secondary__1CQyn{margin-left:10px}.user-tree-button{margin-top:10px;width:120px;height:40px}.button-module_secondary__1CQyn:hover{color:#fff !important}.padding-left{margin-left:10px}.button-module_secondary__1CQyn{margin-left:10px}.button-module_info__3sbf_:hover{color:#0078ab !important}.return-icon{cursor:pointer}.return-icon path{fill:#99adb6}.search-icon{cursor:pointer}

@font-face{font-family:"Wigrum";src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot");src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot?#iefix") format("embedded-opentype"),url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.woff") format("woff");font-weight:300;font-style:normal}@font-face{font-family:"Wigrum";src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot");src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot?#iefix") format("embedded-opentype"),url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.woff") format("woff");font-weight:500;font-style:normal}@font-face{font-family:"Wigrum";src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot");src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot?#iefix") format("embedded-opentype"),url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.woff") format("woff");font-weight:700;font-style:normal}.without-padding{padding-right:0px !important;padding-left:0px !important}.form-control:focus{box-shadow:none;border-color:#ced4da}textarea{border-radius:5px;border-color:#ced4da;outline:none}textarea:hover,input:hover,textarea:active,input:active,textarea:focus,input:focus{outline:0px !important;-webkit-appearance:none;box-shadow:none !important;border-color:#ced4da !important}.is-invalid:hover,.is-invalid:focus,.is-invalid:active{box-shadow:none !important;border-color:#dc3545 !important}.alertmessage-module_messageBox__2juVW{margin:0px !important}.hidden-scroll::-webkit-scrollbar{display:none}.hidden-scroll{-ms-overflow-style:none;scrollbar-width:none}.ups-title{font-family:Wigrum, Helvetica, Arial, sans-serif !important;font-size:28px;font-weight:bold;color:#2c3439}

@font-face{font-family:"Wigrum";src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot");src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot?#iefix") format("embedded-opentype"),url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.woff") format("woff");font-weight:300;font-style:normal}@font-face{font-family:"Wigrum";src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot");src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot?#iefix") format("embedded-opentype"),url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.woff") format("woff");font-weight:500;font-style:normal}@font-face{font-family:"Wigrum";src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot");src:url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot?#iefix") format("embedded-opentype"),url("//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.woff") format("woff");font-weight:700;font-style:normal}body{color:#707579;font-family:"Nunito Sans", sans-serif;font-size:16px}

