@import "../../styles/brand-colors";

.floating-panel {
    font-family: "Nunito";
    border-radius: 0 0 0.2rem;
    box-shadow: -2px 0 4px -2px #cfcfcf;
    color: $dusk-lighter;
    border-left: 1px solid #dde1e5;

    & .property-row {
        padding-top: 15px;
    }

    .css-1lwbda4-MuiStack-root {
        padding-right: 0;
    }

    & .modal-module_modalClose__pvct8 {
        margin-top: -8px;
        left: 4%;
        right: unset;
    }

    & .modal-title {
        padding: 10px;
        width: 100%;
        display: inline-grid;
        & b {
            text-align: center;
        }
    }
    & hr {
        margin: 0 0 10px 0;
    }
    & .modal-body {
        margin: 14px 22px 8px 22px;

        & p {
            size: 16px;
            color: $dusk-lighter;
        }
        & input.radio {
            width: auto;
            size: 20px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 4px;
        }

        & input:not(:focus) {
            color: $dusk-lighter;
        }
        & textarea {
            width: 100%;
            padding: 5px 8px 5px 8px;
            &:not(:focus) {
                color: $dusk-lighter;
            }
        }
    }
}

.table-container {
    border: 1px solid $gray-four;
    border-radius: 5px;
    margin-left: 0 !important;

    & thead {
        height: 45px;
        border-bottom: 1px solid $gray-four;
    }

    & tr:not(:last-child) {
        border-bottom: 1px solid $gray-four;
    }
}

.close-icon {
    cursor: pointer;
    & path {
        fill: $dusk-lightest;
    }
}

.main-title {
    text-align: center;
}

.PrivateNotchedOutline-root-1 {
    top: 0 !important;
}

.footer {
    padding: 10px !important;
    text-align: right;
}

.modal-body-custom {
    height: 530px;
    overflow-y: auto !important;
}

.radio-group-title {
    padding-top: 10px !important;
}

.tree-checkbox-radio {
    color: $midnight-40 !important;

    &:hover {
        background-color: $midnight-0 !important;
    }
    &.Mui-checked {
        color: $lake !important;
    }

    &.Mui-disabled {
        opacity: 0.5;
    }
}

textarea {
    &:focus {
        outline: none !important;
    }
}

textarea::placeholder {
    color: $input-border;
}

.MuiInputBase-input {
    min-height: 26px;
    font-size: 14px !important;
}
