@import "../../styles/brand-colors";

.category-defaults {
    background: $midnight-4;
    border-radius: 0 0 8px 8px;
    padding: 10px;
}

.container-tree {
    padding: 10px 0px;
}

.tree-icon-color {
    & path {
        fill: $white;
    }
}

.MuiTreeItem-group {
    border-left: 1px solid $dusk-lighter !important;
    border-radius: 0 10px !important;
}

.MuiTreeItem-group .category-defaults {
    border-left: 1px solid #f5f7f8 !important;
    margin-left: -2px;
}

.css-po2otw {
    margin: 50px !important;
}

hr {
    margin: 5px 0 5px 0 !important;
}

div {
    font-family: Nunito !important;
}
.pill {
    margin: 3px 3px 0px 0px;
    background-color: $dusk-thirty !important;
    color: white !important;
}
